<template>
  <div class="experiences-new">
    <div class="font-weight-bold mb-3">
      <router-link class="disabled--text" to="/experiences">Experiences</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">Create new experience</span>
    </div>
    <v-card outlined>
      <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
        <div class="text-h6 font-weight-bold">Create new Experience</div>
        <div class="text--text mb-5">Provide general information about experience</div>
        <div class="font-weight-bold mb-3">Category</div>
        <v-select
          class="field46"
          v-model="data.category"
          placeholder="Select category"
          :error-messages="categoryErrors"
          :items="itemsCategory"
          item-text="title"
          item-value="name"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div class="font-weight-bold mb-3">Expert</div>
        <v-autocomplete
          class="field46 me-md-3"
          v-model="data.expert"
          placeholder="Select expert"
          :error-messages="expertErrors"
          :items="expertList"
          :item-text="textItem"
          item-value="id"
          return-object
          outlined
          dense
          required
          color="primary"
          height="46"
        >
          <template v-slot:selection="data">{{ data.item.first_name }} {{ data.item.last_name }}</template>
          <template v-slot:item="data">{{ data.item.first_name }} {{ data.item.last_name }}</template>
        </v-autocomplete>
        <div class="font-weight-bold mb-3">Destination country/city</div>
        <div class="d-flex flex-md-row flex-column" v-for="(item, i) in data.destinations" :key="i">
          <v-autocomplete
            class="field46 me-md-3"
            v-model="item.country"
            @change="getCity(item.country.id, i)"
            placeholder="Country"
            :items="countryList"
            item-text="name"
            item-value="id"
            return-object
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-autocomplete>
          <v-autocomplete
            class="field46 mt-sm-0 mt-n2 ms-md-3"
            v-model="item.city"
            placeholder="City"
            :error-messages="desCityErrors"
            @change="i + 1 == data.destinations.length ? data.destinations.push({ city: {}, country: {} }) : ''"
            :items="townList[i]"
            item-text="name"
            item-value="id"
            return-object
            outlined
            dense
            required
            color="primary"
            height="46"
            :readonly="!item.country.id"
          ></v-autocomplete>
        </div>
        <div class="font-weight-bold mb-3">Availability dates</div>
        <div class="d-flex flex-md-row flex-column">
          <vc-date-picker
            locale="en"
            :input-debounce="500"
            v-model="data.available_since"
            :masks="masks"
            :model-config="modelConfig"
            :first-day-of-week="2"
            :min-date="new Date()"
            is-required
            color="blue"
            class="v-input width100 me-md-3"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                class="field46"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="From"
                :error-messages="fromErrors"
                outlined
                dense
                color="primary"
                height="46"
                readonly
              >
                <template v-slot:append>
                  <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                </template>
              </v-text-field>
            </template>
          </vc-date-picker>
          <vc-date-picker
            locale="en"
            :input-debounce="500"
            v-model="data.available_till"
            :masks="masks"
            :model-config="modelConfig"
            :first-day-of-week="2"
            :min-date="data.available_since"
            is-required
            color="blue"
            class="v-input width100 mt-sm-0 mt-n2 ms-md-3"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                class="field46"
                :value="inputValue"
                v-on="inputEvents"
                placeholder="To"
                :error-messages="toErrors"
                outlined
                dense
                color="primary"
                height="46"
                readonly
              >
                <template v-slot:append>
                  <v-img class="mt-2" src="@/assets/icon/calendar.svg"></v-img>
                </template>
              </v-text-field>
            </template>
          </vc-date-picker>
        </div>
        <div class="font-weight-bold mb-3">Duration, hours</div>
        <v-text-field
          class="field46"
          v-model="data.duration_hours"
          placeholder="0"
          :error-messages="durationErrors"
          type="number"
          outlined
          dense
          color="primary"
          height="46"
        ></v-text-field>
        <div class="d-flex align-md-center flex-md-row flex-column">
          <div class="v-input_small d-block width100 me-md-3">
            <div class="font-weight-bold mb-3">Price</div>
            <v-text-field
              class="field46 v-input_small"
              v-model="data.priceN"
              placeholder="From"
              :error-messages="priceErrors"
              suffix="SAR"
              outlined
              dense
              color="primary"
              height="46"
            ></v-text-field>
          </div>
          <div class="v-input_small d-block width100 mt-md-0 mt-n2 mx-md-3">
            <div class="font-weight-bold mb-3">VAT</div>
            <v-select
              class="field46"
              v-model="data.vat_option"
              placeholder="Select VAT"
              :error-messages="vatOptionEnErrors"
              :items="itemsVat"
              item-text="title"
              item-value="id"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-select>
          </div>
          <div class="v-input width100 mt-md-0 mt-n2 ms-md-3 mb-md-0 mb-3">
            <v-checkbox class="py-2 mt-3" color="success" v-model="data.vat_included" hide-details>
              <template v-slot:label>
                <div class="ms-2">
                  <div class="black--text">VAT included in price</div>
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
        <div class="d-flex align-md-center flex-md-row flex-column">
          <div class="v-input_small d-block width100 me-md-3"></div>
          <div class="v-input_small d-block width100 mt-md-0 mt-n2 mx-md-3">
            <div class="font-weight-bold mb-3">Service fee</div>
            <v-select
              class="field46"
              v-model="data.service_fee_option"
              placeholder="Select service fee"
              :error-messages="serviceFeeOptionEnErrors"
              :items="itemsServiceFee"
              item-text="title"
              item-value="id"
              outlined
              dense
              required
              color="primary"
              height="46"
            ></v-select>
          </div>
          <div class="v-input width100 mt-md-0 mt-n2 ms-md-3">
            <v-checkbox class="py-2 mt-3" color="success" v-model="data.service_fee_included" hide-details>
              <template v-slot:label>
                <div class="ms-2">
                  <div class="black--text">Service fee included in price</div>
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">Description</div>
        <div class="text--text mb-3">Provide the information in English and Arabic</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data.title_en"
            placeholder="Title"
            :error-messages="desTitleEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.title_ar"
            placeholder="عنوان"
            :error-messages="desTitleArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data.short_description_en"
            placeholder="Short description"
            :error-messages="desShortEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.short_description_ar"
            placeholder="وصف قصير"
            :error-messages="desShortArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-textarea
            class="width100 me-md-3"
            v-model="data.description_en"
            placeholder="Description"
            :error-messages="desDesEnErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.description_ar"
            placeholder="وصف كامل"
            :error-messages="desDesArErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="text-end py-3 px-sm-10 px-3">
        <v-btn @click="createItem" depressed color="primary" large width="136">Create</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      data: { destinations: [{ city: {}, country: {} }], vat_included: false, service_fee_included: false },
      idx: null,
      masks: {
        input: 'MMM D, YYYY',
      },
      modelConfig: {
        type: 'number',
        mask: 'iso',
      },
      itemsCategory: [
        { name: 'economy', title: 'Economy' },
        { name: 'standard', title: 'Standard' },
        { name: 'vip', title: 'VIP' },
      ],
      townList: [],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getExpertShort');
    this.$store.dispatch('getCountryList');
    this.$store.dispatch('getSettingsVat', '?all=true');
    this.$store.dispatch('getSettingsServiceFee', '?all=true');
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Experience was successfully created',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'error') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    textItem: (item) => `${item.first_name} ${item.last_name}`,
    async getCity(i, idx) {
      this.townList.splice(idx, 1);
      this.idx = idx;
      await this.$store.dispatch('getCityList', i, idx);
    },
    async createItem() {
      this.error = [];
      const data = new Object();
      this.data.expert ? (data.expert = this.data.expert.id) : '';
      data.category = this.data.category;
      if (this.data.destinations.length >= 0) {
        const array = [];
        for (let i = 0; i < this.data.destinations.length; i++) {
          array.push(this.data.destinations[i].city.id);
        }
        data.destinations = array;
      }
      data.available_since = this.data.available_since;
      data.available_till = this.data.available_till;
      data.duration_hours = this.data.duration_hours;
      data.title_en = this.data.title_en;
      data.title_ar = this.data.title_ar;
      data.short_description_en = this.data.short_description_en;
      data.short_description_ar = this.data.short_description_ar;
      data.description_en = this.data.description_en;
      data.description_ar = this.data.description_ar;
      data.price = this.data.priceN * 100;
      data.vat_option = this.data.vat_option;
      data.vat_included = this.data.vat_included;
      data.service_fee_option = this.data.service_fee_option;
      data.service_fee_included = this.data.service_fee_included;
      await this.$store
        .dispatch('createExp', data)
        .then(() => {
          this.notifi('done');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          e.response.status > 401 ? this.notifi('error') : '';
        });
    },
  },
  watch: {
    cityList(val) {
      if (this.idx != null) {
        this.townList.splice(this.idx, 0, val);
        this.idx = null;
      } else if (val) {
        this.townList.push(val);
      }
    },
  },
  computed: {
    expertList() {
      return this.$store.getters.expertsListShort;
    },
    countryList() {
      return this.$store.getters.countryList;
    },
    cityList() {
      return this.$store.getters.cityList;
    },
    itemsVat() {
      return this.$store.getters.settingsVat;
    },
    itemsServiceFee() {
      return this.$store.getters.settingsServiceFee;
    },
    categoryErrors() {
      const errors = [];
      this.error.find((item) => item == 'category__required') && errors.push('Please select category');
      this.error.find((item) => item == 'category__invalid') && errors.push('Selected category is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    expertErrors() {
      const errors = [];
      this.error.find((item) => item == 'expert__required') && errors.push('Please select trip expert');
      this.error.find((item) => item == 'expert__invalid') && errors.push('Selected trip expert is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desCityErrors() {
      const errors = [];
      this.error.find((item) => item == 'destination_city__required') && errors.push('Please enter destination city');
      this.error.find((item) => item == 'destination_city__invalid') && errors.push('Provided destination city is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    fromErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_since__required') && errors.push('Please enter available since');
      this.error.find((item) => item == 'available_since__invalid') && errors.push('Provided available since is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    toErrors() {
      const errors = [];
      this.error.find((item) => item == 'available_till__required') && errors.push('Please enter available till');
      this.error.find((item) => item == 'available_till__invalid') && errors.push('Provided available till is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    durationErrors() {
      const errors = [];
      this.error.find((item) => item == 'duration_hours__required') && errors.push('Please enter duration hours');
      this.error.find((item) => item == 'duration_hours__invalid') && errors.push('Provided duration hours is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    priceErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price, SAR');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desTitleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desTitleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desShortEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description_en__required') && errors.push('Please enter short description');
      this.error.find((item) => item == 'short_description_en__invalid') && errors.push('Provided short description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desShortArErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description_ar__required') && errors.push('Please enter short description');
      this.error.find((item) => item == 'short_description_ar__invalid') && errors.push('Provided short description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desDesEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    desDesArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    vatOptionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'vat_option__required') && errors.push('Please select VAT type');
      this.error.find((item) => item == 'vat_option__invalid') && errors.push('Selected VAT type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    serviceFeeOptionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'service_fee_option__required') && errors.push('Please select service fee type');
      this.error.find((item) => item == 'service_fee_option__invalid') && errors.push('Selected service fee type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setCountryList');
    this.$store.dispatch('setCityList');
    this.$store.dispatch('setExpertShort');
  },
};
</script>

<style lang="scss">
#app .experiences-new {
  .v-input {
    max-width: 371px;
  }
  .v-input_small {
    max-width: 186px;
  }
  @media screen and (max-width: 700px) {
    .v-input_small,
    .v-input {
      max-width: initial !important;
    }
  }
}
</style>
